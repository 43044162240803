import styled from 'styled-components'
import { colors, device } from '../../styles/vars'

const MenuContainer = styled.nav`
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 0;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: 1rem;
    padding-left: 1rem;
    box-sizing: border-box;
    li {
      margin: 1.2rem;
      a,
      button {
        font-size: 1.4rem;
        @media ${device.laptop} {
          font-size: 1rem;
        }
        font-weight: 700;
        text-decoration: none;
        color: ${colors.red};
        background-color: transparent;
        border-bottom: 2px solid transparent;
        padding-bottom: 0rem;
        transition: all 0.3s ease;
        border: 0;
        cursor: pointer;
        &:hover,
        &.active {
          border-bottom: 2px solid ${colors.red};
          padding-bottom: 0.25rem;
        }
        &.cta {
          color: #fff;
          background-color: ${colors.red};
          padding: 0.6rem 0.8rem;
          border-radius: 0.6rem;
          margin-left: 0.5rem;
          &:hover,
          &.active {
            border-bottom: 0px solid ${colors.red};
            padding: 0.6rem 0.8rem;
            background-color: ${colors.lightBlue};
          }
        }
      }
      &.left {
        margin-left: auto;
      }
    }
  }
`
export { MenuContainer }
