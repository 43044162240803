import React from 'react'
import { Link } from 'gatsby'

import { AuthUserContext } from '../Session'
import SignOutButton from '../SignOut'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'

import { MenuContainer } from './NavigationStyles'
import { Logotype } from './../../styles/globalStyles'

const Navigation = ({ dark = false }) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <NavigationAuth authUser={authUser} dark={dark} />
      ) : (
        <NavigationNonAuth dark={dark} />
      )
    }
  </AuthUserContext.Consumer>
)

const NavigationAuth = ({ authUser, dark = false }) => (
  <MenuContainer>
    <ul>
      <li>
        <Link to={ROUTES.LANDING} activeClassName='active'>
          <Logotype dark={dark} />
        </Link>
      </li>
      <li>
        <Link to={ROUTES.HOME} activeClassName='active'>
          Your sites
        </Link>
      </li>
      <li>
        <Link to={ROUTES.ACCOUNT} activeClassName='active'>
          Account
        </Link>
      </li>
      {!!authUser.roles[ROLES.ADMIN] && (
        <li>
          <Link to={ROUTES.ADMIN} activeClassName='active'>
            Admin
          </Link>
        </li>
      )}
      <li className='left'>
        <SignOutButton className='cta' />
      </li>
    </ul>
  </MenuContainer>
)

const NavigationNonAuth = ({ dark = false }) => (
  <MenuContainer>
    <ul>
      <li>
        <Link to={ROUTES.LANDING} activeClassName='active'>
          <Logotype dark={dark} />
        </Link>
      </li>
      <li>
        <Link to={ROUTES.FEATURES} activeClassName='active'>
          Features
        </Link>
      </li>
      <li>
        <Link to={ROUTES.PLANS} activeClassName='active'>
          Plans
        </Link>
      </li>
      <li className='left'>
        <Link to={ROUTES.SIGN_IN} activeClassName='active'>
          Sign In
        </Link>
      </li>
      <li>
        <Link className='cta' to={ROUTES.SIGN_IN} activeClassName='active'>
          Create account
        </Link>
      </li>
    </ul>
  </MenuContainer>
)

export default Navigation
