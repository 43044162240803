const colors = {
  blue: '#1F49CC',
  lightBlue: 'rgb(16, 145, 224)',
  backgroundBlue: 'rgba(16, 145, 224, 0.4)',
  blueOpac: (opac = 0.4) => `rgba(16, 145, 224, ${opac})`,
  red: '#FF4A4A',
  green: '#00aa60',
  grey: '#f3f3f3',
  greyDarker: '#ededed',
  textBlack: '#494949',
  titleBlack: '#999999',
  backgroundGrey: '#999999',
}
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '625px',
  tabletL: '768px',
  laptopS: '1025px',
  laptopM: '1300px',
  laptopL: '1440px',
  desktop: '2560px',
}
const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tabletS: `(min-width: ${size.tabletS})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptopS})`, // styles for print
  laptopS: `(min-width: ${size.laptopS})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.laptopL})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export { colors, device }
