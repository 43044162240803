export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const FEATURES = '/features'
export const PLANS = '/plans'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin/:id'
