import styled, { createGlobalStyle } from 'styled-components'
import { colors, device } from './vars'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: calc(9px + 0.2vw);
    @media ${device.laptop} {
      font-size: calc(7px + 0.5vw);
    }
  }
  body,html {
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    min-height: 100vh;
    color: ${colors.textBlack};
  }
  body, input, button, form {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  h1 {
    font-size: 3rem;
    line-height: 1.1em;
    color: ${colors.red};
    font-weight: 700;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
`
const PageContainer = styled.div`
  margin: 0 8vw;
`

const FormPillow = styled.form`
  display: block;
  input,
  button {
    font-size: 1rem;
  }
  input {
    border: 0;
    background-color: ${colors.grey};
    margin-right: 0rem;
    padding: 0.8rem 1rem;
    background-color: ${colors.blue};
    border-bottom: 2px solid ${colors.lightBlue};
    color: ${colors.lightBlue};
    ::placeholder {
      color: ${colors.lightBlue};
    }
  }
  button {
    color: #fff;
    display: inline-block;
    cursor: pointer;
    border: 0;
    margin: 0.7rem 0;
    padding: 0.8rem 1.3rem;
    background-color: ${colors.lightBlue};
    transition: all 0.4s ease;
    border-bottom: 2px solid ${colors.lightBlue};
    svg {
      padding-right: 0.8rem;
    }
    &:hover {
      background-color: ${colors.blue};
    }
  }
  form {
    display: inline-block;
  }
`

const CircleIllu = styled.div`
  position: absolute;
  z-index: 1;
  width: ${({ xxl = false }) => (xxl ? '120vw' : '75vw')};
  height: ${({ xxl = false }) => (xxl ? '120vw' : '75vw')};
  background-color: ${({ color = 'grey' }) => colors[color]};
  top: ${({ xxl = false }) => (xxl ? '20vh' : '15vh')};
  opacity: 0.95;
  right: ${({ xxl = false }) => (xxl ? '-110vh' : '-30vh')};
  border-radius: 50%;
`

const Logotype = styled.div`
  border: 0.23rem solid ${colors.red};
  width: 0.6rem;
  height: 0.6rem;
  position: relative;
  transition: all 0.5s ease;
  ${({ dark = false }) => {
    return dark ? 'z-index: 4' : ''
  }};
  &::before {
  }
  &::after {
    content: '';
    border: 0.25rem solid
      ${({ dark = false }) => {
        return dark ? '#fff' : colors.greyDarker
      }};
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    border-radius: 50%;
    transform: translate(-60%, -62%);
    z-index: -1;
    transition: all 0.3s ease;
  }
  &:hover {
    border-color: ${colors.lightBlue};
  }
`

const PageHeaderMargin = styled.div`
  height: 5vh;
`
const PageHeader = styled.section`
  display: flex;
  margin: 1rem 0;
  align-items: ${({ align = 'center' }) => align};
  h1 {
    margin: 0;
    margin-top: 0;
  }
  & > div {
    margin-left: auto;
  }
`

export {
  GlobalStyle,
  PageContainer,
  FormPillow,
  CircleIllu,
  Logotype,
  PageHeaderMargin,
  PageHeader,
}
